import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";

class TransactionListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where =
      user.roles[0].name === "Admin"
        ? {}
        : {
            createdBy: user.id,
          };
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  // async findObjects() {
  //   const user = this.view.getCurrentUser();
  //   const collection = this.view.getCollectionName();
  //   const query = this.createQuery();

  //   try {
  //     this.showProgress();
  //      // Assuming there's a way to track page or offset for infinite scroll
  //      query.page = this.page || 1; // Track the current page for infinite scroll (if using page)
  //      query.limit = this.limit || 20; // Example limit for each page
  //     this.findObjectUseCase.abort();

  //     const objects = await this.findObjectUseCase.execute(collection, query);
  //     this.objects = this.objects.concat(objects);
  //     this.view.setTotal(this.objects.length);
  //     this.view.setObjects(this.objects);
  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  //   this.progress = false;
  // }
  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();

    try {
      this.showProgress();

      // Assuming there's a way to track page or offset for infinite scroll
      query.page = this.page || 1; // Track the current page for infinite scroll (if using page)
      query.limit = this.limit || 20; // Example limit for each page

      this.findObjectUseCase.abort();

      const objects = await this.findObjectUseCase.execute(collection, query);

      // Avoid duplicates: only concatenate new objects
      const newObjects = objects.filter(
        (obj) => !this.objects.some((existing) => existing.id === obj.id)
      );
      this.objects = this.objects.concat(newObjects);

      // Set objects and update view
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);

      // Increment the page for the next fetch (if using page-based pagination)
      this.page = (this.page || 1) + 1;

      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  async onClickItem(index, field) {
    const object = this.objects[index];
    const getQuote = new FindObjectUseCase();
    const getPDF = await getQuote.execute("quotations", {
      where: { id: object.quote },
    });
    const res = getPDF[0].quote_file;

    try {
      window.open(res, "_blank");
    } catch (error) {
      console.error("There was an error opening the PDF:", error);
    }
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onClickExport() {
    this.view.exportPDF();
  }
}

export default TransactionListPresenter;
