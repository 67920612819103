import BaseFormPresenter from "../../../base/BaseFormPresenter";

class UserFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.role = this.change.roles[0].name;
      this.change.acl = this.view.getAcl();
      this.change.status = "ACTIVE";
      if (this.change.password !== this.change.confirmPassword) {
        console.log("chnaged", this.change);
        return this.view.showError("Confirm Password is not match!");
      }
      console.log("chnaged", this.change);
    }
    try {
      if (this.change.password === this.change.confirmPassword) {
        delete this.change.confirmPassword;
        await this.upsertUseCase.execute(collection, this.change);
      }
      this.change = {};
      if (this.change.password === this.change.confirmPassword) {
        this.view.submissionSuccess();
        this.view.showSuccessSnackbar("User Created Successfully!");
        this.view.navigateTo("/users");
      }
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }
  async submit() {
    console.log("get role", this.change.roles, this.object.roles);
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      // this.view.submissionSuccess();
      // this.view.showSuccessSnackbar("User Created Successfully!");
      // this.view.navigateTo("/users");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default UserFormPresenter;
